<template>
  <div class="rounded">
    <div class="avatar-popup" :class="{ visible: showPopup }">
      <slot name="popup"></slot>
    </div>
    <div class="avatar" :style="avatarStyle" @click="togglePopup"></div>
  </div>
</template>

<script>
export default {
  name: "RoundedAvatar",
  props: ["image", "name", "showPopup"],
  computed: {
    avatarStyle() {
      return this.image ? `background-image: url(${this.image})` : "";
    },
  },
  methods: {
    togglePopup(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("togglePopup");
    },
  },
};
</script>

<style scoped lang="less">
.rounded {
  position: relative;
  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    border: solid 1px var(--base-color);
    overflow: hidden;
    cursor: pointer;
    background: url("../../../assets/images/userpic.png") #fff no-repeat center
      center;
    background-size: cover;
    z-index: 50;
  }
  &.highlighted {
    .avatar {
      border: 2px solid #65ff00;
    }
  }
  .avatar-popup {
    font-size: 10px;
    position: absolute;
    width: 110px;
    bottom: 103%;
    right: -36px;
    background-color: var(--base-color);
    color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    &.visible {
      opacity: 1;
    }
  }
}
</style>
