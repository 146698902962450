<template>
  <div class="mountain-game" :class="{ tiny: isSideBlockOpen }" @click="closePopups()">
    <transition name="throbber-fade">
      <div v-if="throbberIsActive" class="load-throbber">
        <div class="throbber"></div>
      </div>
    </transition>
    <div v-show="!isGameActive" class="mountain">
      <div class="bg-block bg">
        <img
          src="../../../assets/images/abbott/mountain/mountain.jpg"
          alt=""
          @load="checkBackgroundsLoaded('mountainBgIsLoaded')"
        />
      </div>
      <div class="game-title">{{ $t("testNGames.kingOfTheMountain") }}</div>
      <div class="leaders-set-wrapper">
        <div class="leaders-set">
          <div
            v-for="(leader, index) in leaders"
            :key="leader.id"
            class="leader"
            :class="'place' + index"
          >
            <div class="line-to-bottom" :class="{ you: leader.itsYou }"></div>
            <rounded-avatar
              :image="leader.image"
              :name="leader.name"
              :class="{ highlighted: leader.itsYou }"
              :showPopup="index === popupToShow"
              :style="{ zIndex: 21, position: 'relative' }"
              @togglePopup="togglePopup(index)"
            >
              <div slot="popup" class="popup-content">
                <div class="popup-name">
                  {{ leader.name || $t("common.guest") }}
                </div>
                <div class="popup-place">{{ leader.place }} {{ $t("testNGames.place") }}</div>
                <div class="popup-place">{{ $t("testNGames.points") }} : {{ leader.points }}</div>
              </div>
            </rounded-avatar>
          </div>
        </div>
      </div>
      <div class="description-wrapper">
        <div class="description-block">
          <div class="description-title">{{ $t("testNGames.gameRules") }}</div>
          <div class="description-text">
            {{ $t("testNGames.mountainGameRulesText") }}
          </div>
          <div class="poll-control-btn-block">
            <poll-control-button class="start-game" @click="startGame">
              {{ $t("testNGames.startGame") }}
            </poll-control-button>
            <poll-control-button v-if="showResults" class="start-game" @click="showResult">
              {{ $t("testNGames.result") }}
            </poll-control-button>
          </div>
        </div>
      </div>
      <div class="bg-block mask">
        <img
          src="../../../assets/images/abbott/mountain/mount-mask.png"
          alt=""
          @load="checkBackgroundsLoaded('mountainMaskIsLoaded')"
        />
      </div>
    </div>
    <div v-show="isGameActive" class="game-test">
      <game game-alias="king">
        <template slot="postScreen">
          <div class="after-game-message">
            <king-mountains-result :point-king="gameKingResult" :button-back="false" />
            <poll-control-button class="after-screen-button" @click="closePostGameScreen">
              {{ $t("testNGames.close") }}
            </poll-control-button>
          </div>
        </template>
      </game>
    </div>
    <king-mountains-result
      v-if="showPoint"
      :button-back="true"
      :point-king="gameKingResult"
      class="game-result-king"
      @back="showPoint = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PollControlButton from "@/components/common/polls/components/common/PollControlButton";
import KingMountainsResult from "@/components/event/room/KingMountainResult.vue";
import { games, polls, room } from "@/store/modules/store.namespaces";
import { KING_LEADERS, KING_USER_SCORE } from "@/store/modules/polls/getter-types";
import {
  GAME_OVER,
  RUN_MOUNTAIN_KING,
  GET_KING_RESULT,
  GET_GAMES,
} from "@/store/modules/games/action-types";
import Game from "./Game";
import RoundedAvatar from "../../common/elements/RoundedAvatar";

export default {
  name: "MountainGame",
  components: {
    PollControlButton,
    RoundedAvatar,
    Game,
    KingMountainsResult,
  },
  data() {
    return {
      showPoint: false,
      isTestStarted: false,
      popupToShow: null,
      throbberIsActive: true,
      leadersData: [],
      mountainBgIsLoaded: false,
      mountainMaskIsLoaded: false,
    };
  },
  computed: {
    ...mapGetters(polls, {
      leaders: KING_LEADERS,
      userScore: KING_USER_SCORE,
    }),
    ...mapState(games, {
      showResults: state => state.gameKingResult.test_time !== 0,
      userPoints: state => state.king.points,
    }),
    ...mapState(games, ["currentActiveGame", "gameKingResult"]),
    ...mapState(room, ["sidebar", "roomId"]),
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    isSideBlockOpen() {
      return this.sidebar.expanded;
    },
    isGameActive() {
      return this.currentActiveGame.name === "king";
    },
  },
  watch: {
    roomId: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.gameOver();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getGames();
    await this.getKingResult();
  },
  beforeDestroy() {
    const needClearPoll = this.$route.name !== "interactive";
    this.gameOver(needClearPoll);
  },
  methods: {
    ...mapActions(games, {
      getGames: GET_GAMES,
      startGame: RUN_MOUNTAIN_KING,
      gameOver: GAME_OVER,
      getKingResult: GET_KING_RESULT,
    }),
    showResult() {
      this.showPoint = !this.showPoint;
    },
    closePostGameScreen() {
      this.gameOver();
    },
    togglePopup(index) {
      this.popupToShow = index === this.popupToShow ? null : index;
    },
    closePopups() {
      this.popupToShow = null;
    },
    checkBackgroundsLoaded(bgType) {
      this[bgType] = true;
      if (this.mountainBgIsLoaded && this.mountainMaskIsLoaded) {
        this.throbberIsActive = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.throbber-fade-enter-active,
.throbber-fade-leave-active {
  transition: opacity 0.8s;
}
.throbber-fade-enter,
.throbber-fade-leave-to {
  opacity: 0;
}
.mountain-game {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  .load-throbber {
    background-color: white;
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .throbber {
      width: 50px;
      height: 50px;
      background: url("../../../assets/images/abbott/ajax-loader_abbott.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .game-title {
    font-size: 32px;
    display: inline-block;
    position: absolute;
    left: 45px;
    top: 25px;
    z-index: -1;
  }
  .mountain {
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    z-index: 5;
    min-height: 600px;
    .leaders-set-wrapper {
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      z-index: 10;
      .leaders-set {
        position: relative;
        max-width: 630px;
        height: 100%;
        margin: 0 auto;
        overflow-y: hidden;
        overflow-x: visible;
        @media screen and (min-width: 1350px) {
          max-width: 800px;
        }
        .leader {
          position: absolute;
          .line-to-bottom {
            height: 15000px;
            width: 1px;
            background-color: #f28b00;
            position: absolute;
            top: 0;
            left: calc(50% - 1px);
            &.you {
              width: 1px;
              background-color: #65ff00;
            }
          }
          &.place0 {
            left: 88%;
            top: 9%;
          }
          &.place1 {
            left: 80%;
            top: 13%;
          }
          &.place2 {
            left: 72%;
            top: 19%;
          }
          &.place3 {
            left: 64%;
            top: 25%;
          }
          &.place4 {
            left: 56%;
            top: 30%;
          }
          &.place5 {
            left: 47%;
            top: 33%;
          }
          &.place6 {
            left: 37%;
            top: 36%;
          }
          &.place7 {
            left: 27%;
            top: 38%;
          }
          &.place8 {
            left: 16%;
            top: 39%;
          }
          &.place9 {
            left: 5%;
            top: 40%;
          }
        }
      }
    }
    .description-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 10px;
      z-index: 25;
      .description-block {
        max-width: 550px;
        background-color: white;
        margin: 10px auto 40px;
        border-radius: 4px;
        padding: 17px;
        text-align: center;
        @media screen and (min-width: 1350px) {
          max-width: 750px;
        }
        .description-title {
          font-size: 21px;
          margin-bottom: 25px;
        }
        .start-game {
          margin: 25px auto 5px;
        }
      }
    }
    .bg-block {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      /*z-index: 10;*/
      pointer-events: none;
      //object-fit: cover;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &.mask {
        z-index: 20;
      }
    }
  }
}

.game-test {
  width: 100%;
  margin: 60px auto 0;

  ::v-deep .after-game-message {
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }

  .after-screen-button {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.game-result-king {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 60px;
  z-index: 9;
  background: #fff;
}

.poll-control-btn-block {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 550px) {
  .mountain-game {
    .game-test {
      max-width: 100%;
      padding: 0 15px;
    }
  }
}
</style>
