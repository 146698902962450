<template>
  <div>
    <back-button v-if="buttonBack" class="back-btn" @go-back="back" />
    <div class="result">
      <div class="test-result">
        <div class="test-result__donut">
          <pie-chart
            legend="false"
            :library="{
              cutoutPercentage: 60,
              elements: { arc: { borderWidth: 0 } },
              tooltips: { enabled: false },
            }"
            width="140px"
            height="140px"
            :suffix="' ' + pointKing.percent"
            :data="[
              [$t('testNGames.incorrectAnswers'), 100 - pointKing.percent],
              [$t('testNGames.correctAnswers'), pointKing.percent],
            ]"
            :colors="['#e8e9e8', '#209c29']"
            donut="true"
          ></pie-chart>
          <div class="test-result__donut-center">
            + {{ pointKing.user_points_with_times }} <br />
            балла
          </div>
        </div>
        <div class="test-result__text">
          {{ pointKing.percent }} %
          {{ $t("testNGames.persentsCorrectAnswers") }}
        </div>
        <div class="test-result__text">
          {{ pointKing.test_time }}
        </div>
      </div>
      <div class="result__questions">
        <div
          v-for="(question, index) in questionsKing"
          :key="question + index"
          class="result__question"
        >
          <div
            class="result__toggle icon-arrow-right"
            :class="activeIndex === index ? 'result__toggle--open' : ''"
            @click="toggle(index)"
          >
            <div class="result__question-number" :class="checkQuestion(question)">
              {{ $t("testNGames.question") + " №" + (index + 1) }}
              <span class="points">
                {{ question.user_points_for_question }}
                {{ pointsQuestion(question.user_points_for_question) }}
              </span>
            </div>
          </div>

          <slide-up-down :active="activeIndex === index">
            <div class="result__content">
              <div class="result__text">{{ question.title }}</div>
              <div v-if="question.variants.length" class="result__variants">
                <div
                  v-for="(answer, index) in question.variants"
                  :key="answer + index"
                  class="result__variant"
                >
                  <div class="question" :class="checkOpen(question, answer)">
                    {{ answer.title }}
                  </div>
                </div>
              </div>
            </div>
          </slide-up-down>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import SlideUpDown from "vue-slide-up-down";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
import { room, auth, games } from "@/store/modules/store.namespaces";
import { i18nPlurals } from "@/utils/index";
import BackButton from "@/components/common/elements/BackButton";

if (!Object.keys(Vue.options.components).includes("pie-chart")) {
  Vue.use(VueChartkick, {
    adapter: Chart,
  });
}

export default {
  name: "KingMountainResult",
  components: {
    SlideUpDown,
    BackButton,
  },
  props: {
    pointKing: {
      type: Object,
      default: () => {},
    },
    buttonBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      point: 0,
      activeIndex: 0,
    };
  },
  computed: {
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    ...mapState(auth, {
      userId: state => state.user.id,
    }),
    ...mapState(games, {
      topUsers: state => state.king.leadersList,
    }),
    questionsKing() {
      const questions = [];
      for (const item in this.pointKing) {
        if (this.pointKing[item].id) {
          questions.push(this.pointKing[item]);
        }
      }
      return questions;
    },
  },
  methods: {
    pointsQuestion(points) {
      return i18nPlurals("point", points, this.locale);
    },
    checkQuestion(question) {
      if (question.partially_completed) {
        return "icon-minus";
      }
      if (question.variants.some(item => item.user_answered_is_right && item.is_user_answer)) {
        return "correct icon-check";
      }
      if (question.variants.some(item => item.is_user_answer && !item.user_answered_is_right)) {
        return "icon-close";
      }
    },
    checkOpen(question, answer) {
      if (answer.user_answered_is_right && answer.is_user_answer) {
        return "result__variant--" + "correct icon-check";
      }
      if (answer.is_user_answer && !answer.user_answered_is_right) {
        return "result__variant--" + "wrong icon-close";
      }
      return "result__variant--" + "not-checked";
    },
    back() {
      this.$emit("back");
    },
    toggle(index) {
      if (this.activeIndex === index) {
        this.activeIndex = false;
      } else {
        this.activeIndex = index;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.result {
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
}

.result__title {
  min-height: 64px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
  text-align: center;
}

.result__questions {
  max-width: 720px;
  margin: 0 auto;
}

.result__question {
  background-color: #ffffff;
  border: solid 1px var(--base-shadow-color);
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.result__question-number {
  position: relative;
  height: 18px;
  padding-right: 30px;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
  text-align: center;
  transform: translateY(-5px);

  &::before {
    position: absolute;
    top: 5px;
    right: 0;
    width: 18px;
    height: 18px;
    font-family: simple-line-icons;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }

  &.icon-check {
    &::before {
      color: var(--positive-color);
    }
  }

  &.icon-close {
    &::before {
      color: var(--negative-color);
    }
  }

  &.icon-minus {
    &::before {
      color: #f28b00;
    }
  }

  &.muted {
    &::before {
      color: #bebebe;
    }
  }
}

.points {
  position: absolute;
  bottom: -17px;
  left: 0;
  font-size: 12px;
  color: #bebebe;
}

.result__toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 14px;
  color: var(--negative-color);
  cursor: pointer;
  user-select: none;

  &::before {
    position: absolute;
    top: 17px;
    right: 36px;
    font-size: 14px;
    color: var(--negative-color);
    transition: all ease 0.3s;
    transform: rotate(0deg);
  }

  &--open {
    &::before {
      color: var(--positive-color);
      transform: rotate(90deg);
    }
  }
}

.result__content {
  box-sizing: border-box;
  max-width: 666px;
  padding: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.result__text {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.4;
  color: var(--base-text-color);
  text-align: left;
}

.result__variant-text {
  height: 17px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 17px;
  color: #949494;
  text-align: left;
}

.result__variant {
  position: relative;
  min-height: 18px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 18px;
  color: var(--base-text-color);
  text-align: left;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    font-family: simple-line-icons;
    font-size: 19px;
    line-height: 19px;
  }

  &--correct {
    color: var(--positive-color);
    &.muted {
      color: #bebebe;
    }
  }

  &--wrong {
    color: var(--negative-color);
  }

  &--missed {
    color: #f28b00;
  }

  &--not-checked::before {
    box-sizing: border-box;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 3px;
    vertical-align: bottom;
    content: "";
    border: solid 1px #b2b2b2;
    border-radius: 50%;
  }
}

.test-result__donut {
  position: relative;
  width: 140px;
  height: 140px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}

.test-result__donut-center {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 84px;
  height: 84px;
  padding-top: 20px;
  padding-bottom: 40px;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--positive-color);
  text-align: center;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  margin: 25% auto;
}

.test-result__text {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  color: var(--positive-color);
  text-align: center;
}

.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>
